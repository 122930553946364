import styled from 'styled-components'

export const Section = styled.section`
  border-bottom: 8px solid ${ (props: { theme: { textColorGray100: string } }) => props.theme.textColorGray100 };
  overflow: hidden;
  
  .react-multi-carousel-list {
    overflow: visible;
  }

  h2, .title {
    color: ${ (props: { theme: { textColorGray500: string } }) => props.theme.textColorGray500 };
  }

  .description {
    color: ${ (props: { theme: { textColorGray400: string } }) => props.theme.textColorGray400 };
  }
`

export const Card = styled.div`
  border: 1px solid ${ (props: { theme: { textColorGray200: string } }) => props.theme.textColorGray200 };
  border-radius: 10px;
  padding: 18px;
  margin-right: 15px;
  height: 440px;
  position: relative;
`

export const Hr = styled.hr`
  border: none;
  border-top: 2px dotted ${ (props: { theme: { textColorGray200: string } }) => props.theme.textColorGray200 };
  margin: 16px 0;
`

export const Disclaimer = styled.div`
  position: absolute;
  bottom: 5px;
  font-size: 10px;
`
