import React from 'react'

import Hero from './sections/hero/_index'
import Levels from './sections/levels/_index'

import { ThemeProvider } from 'styled-components'
import defaultTheme from "./themes/_default"

import { Wrapper } from './style'

const RewardsProgram = () => {
  return (
    <ThemeProvider theme={defaultTheme}>
      <Wrapper>
        <Hero />
        <Levels />
      </Wrapper>
    </ThemeProvider>
  )
}

export default RewardsProgram
