import React from 'react'
import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'
import { BottomSheet } from "@interco/inter-ui/components/BottomSheet"
import FormBottomSheet from './components/FormBottomSheet/_index'

import LevelsJSON from '../../assets/data/Levels.json'
import VerificationStatusCheck from '../../assets/images/ic_verification_status-check.svg'
import VerificationStatusStar from '../../assets/images/ic_verification_status-star.svg'

import * as S from './style'

type LevelsProps = {
  level: string;
  criteria: {
    minimum: string;
    profile: string;
    produce: string;
  }[];
  benefits: string[];
}

const Levels = () => {
  const [ showBottomSheet, setShowBottomSheet ] = React.useState(false)

  return (
    <S.Section className='py-3'>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <DefaultCarousel
              sm={{ items: 1, partialVisibilityGutter: 20 }}
              customDotColor='#EA7100'
              notShowArrows
            >
              {
                LevelsJSON.map((item: LevelsProps, index: number) => {
                  return (
                    <S.Card key={item.level}>
                      <div className='d-flex align-items-center'>
                        <div><h2 className='fs-20 fw-600 mb-0'>{item.level}</h2></div>
                        <div className='ml-2'>
                          {index === 2 ? <img src={VerificationStatusStar} /> : <img src={VerificationStatusCheck} />}
                        </div>
                      </div>
                      <div>
                        <p className='fs-14 fw-600 title mb-0'>Critérios:</p>
                        <p className='fs-14 fw-400 description mb-0'>{item.criteria[0]?.minimum}</p>
                        <p className='fs-14 fw-400 description mb-0'>{item.criteria[0]?.profile}</p>
                        <p className='fs-14 fw-400 description'>{item.criteria[0]?.produce}</p>
                      </div>
                      <S.Hr />
                      <div>
                        <p className='fs-14 fw-600 title mb-0'>Benefícios:</p>
                        {
                          (item.benefits || []).map((benefit: string) => {
                            return (
                              <p key={benefit} className='fs-14 lh-16 mt-2 fw-400 description mb-0'>{benefit}</p>
                            )
                          })
                        }
                        <S.Disclaimer><p className='fw-400 mt-3 description'>Todas as regras serão indicadas nos Termos de Uso.</p></S.Disclaimer>
                      </div>
                    </S.Card>
                  )
                })
              }
            </DefaultCarousel>
            <button
              title='Quero saber mais'
              className='btn btn--lg bg-orange--base text-white text-none mt-4'
              onClick={() => {
                setShowBottomSheet(true)
              }}
            >
              Quero saber mais
            </button>
            {showBottomSheet && (
              <BottomSheet onClose={() => setShowBottomSheet(false)}>
                <FormBottomSheet setShowBottomSheet={setShowBottomSheet} />
              </BottomSheet>
            )}

          </div>
        </div>
      </div>
    </S.Section>
  )
}

export default Levels
